@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");
* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Baloo 2", cursive;
  box-sizing: border-box;
}

.content-container {
  max-width: 1536px; /* or any width you prefer */
  margin: 0 auto;
  padding: 0;
}

.active {
  color: #6666ea !important;
  background-color: #f9f9ff !important;
  border-radius: 0px 30px 30px 0px;
  /* width: 222px !important; */
}
/*radio button style*/
:where(.css-dev-only-do-not-override-1pg9a38).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #6d31ed;
  background-color: #6d31ed;
}

.roundedSelect .ant-select-selector {
  color: #252363;
  border-radius: 20px;
}
.DraftedsSelect .ant-select-selector {
  color: #ff638d;
  border-radius: 10px;
}
.DraftedsSelect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  background-color: rgba(255, 99, 141, 0.1);
  text-align: center;
}
.PublishsSelect .ant-select-selector {
  color: #77d1a4;
  border-radius: 10px;
}
.PublishsSelect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  background-color: rgba(119, 209, 164, 0.1);
  text-align: center;
}
.noRoundedSelect .ant-select-selector {
  color: #d9d9d9;
  border-radius: 10px;
}
/*check box styling */
.custom-checkbox {
  font-size: 18px;
  font-weight: 600;
}

.custom-checkbox .ant-checkbox-inner {
  border-color: #6666ea;
  border-radius: 50%;
}

.activate-checkbox .ant-checkbox-inner {
  border-color: #6666ea;
  border-radius: 50%;
}

/*quill styling*/
.ql-toolbar.ql-snow {
  border: 1px solid #ccccf8 !important;
  background-color: #f2f2fe;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/**/

/*skeleton styles*/
.card-skeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  /* border: 1px solid #ccccf8; */
  border-radius: 10px;
}

.card-skeleton .right-col {
  flex: 0.5;
  margin-top: 10px;
}

/**/
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6666ea;
  border-color: #6666ea;
  border-radius: 50%;
}

.roundedSelect.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  background-color: #f9f9ff;
  text-align: center;
}
.ant-input::placeholder {
  color: #e0e0fb;
}
.noplaceholder.ant-input::placeholder {
  color: #d9d9d9;
}
.noIconplaceholder.ant-input-affix-wrapper.ant-input::placeholder {
  color: #d9d9d9;
}
.custom-button:hover {
  color: #9494f5 !important;
  border-color: #9494f5 !important;
}

/*hover for the event cards*/
.event-card .hover-content {
  display: none;
}

.event-card:hover .hover-content {
  display: block;
}

.event-card .default-content {
  display: block;
}

.event-card:hover .default-content {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
